import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { getCompanyList } from "../../../serverRequest.js";
import NasReportList from "./NasReport/NasReportList.js";
import moment from 'moment';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faDownload } from "@fortawesome/free-solid-svg-icons";

export default function NasReport() {
  const [companies, setCompanies] = useState([]);
  const [companyData, setCompanyData] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [nasData, setNasData] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedFormat, setSelectedFormat] = useState(null);

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
  };

  const handleChange = selectedOption => {
    setCompanyData(selectedOption.value);
    setSelectedOption(selectedOption);
  };

  const fetchCompanyData = async () => {    
      let companyList = await getCompanyList();
      if (companyList && companyList['data']) {
        companyList = companyList['data'];
        let companyData = companyList[0]['value'];
        await setCompanyData(companyData);
        setCompanies(companyList);
        setSelectedOption(companyList[0]);
      }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const handleDownload = (format) => {
    const data = nasData.map((item, index) => ({
        "S.No.": item.id ? item.id : '',
        "Entity Name": item.entity_name ? item.entity_name : '',
        "Host Name": item.host_name ? item.host_name : '',
        "Url Nas": item.url_nas ? item.url_nas : '',
        "Nasuser 1": item.nasuser_1 ? item.nasuser_1 : '',
        "Nasuser 1 Password": item.nasuser_1_password ? item.nasuser_1_password : '',
        "Nasuser 2": item.nasuser_2 ? item.nasuser_2 : '',
        "Nasuser 2 Password": item.nasuser_2_password ? item.nasuser_2_password : '',
        "Quick Connect Id": item.quick_connect_id ? item.quick_connect_id : '',
        "Quick Connect Url": item.quick_connect_url ? item.quick_connect_url : '',
        "Model Number": item.model_number ? item.model_number : '',
        "Serial Number": item.serial_number ? item.serial_number : '',
        "Total Space": item.total_space ? item.total_space : '',
        "Used Space": item.used_space ? item.used_space : '',
        "Hdd Number": item.hdd_number ? item.hdd_number : '',
        "Raid Type": item.raid_type ? item.raid_type : '',
        "Serverhost Name": item.serverhost_name ? item.serverhost_name : '',
        "Task Name": item.task_name ? item.task_name : '',
        "Task Schedule Time": item.task_schedule_time ? item.task_schedule_time : '',
    }));
      
    if (format === "csv") {
      const csvContent = [
          Object.keys(data[0]).join(","),
          ...data.map(item => Object.values(item).map(value => `"${value}"`).join(","))
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "NasReport.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (format === "excel") {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Nas Report");
      XLSX.writeFile(workbook, "NasReport.xlsx");
    }
  };

  const formatOptions = [
    { value: "csv", label: "CSV" },
    { value: "excel", label: "Excel" },
  ];

  return (
    <main>
      <div className="relative block py-24 lg:pt-0 px-4">
        <div className="rounded-t bg-white mb-0 px-4 py-6">
          <div className="flex items-center justify-between">
            <div className="lg:w-3/12 px-2 items-left">
              <h6 className="text-blueGray-700 text-xl font-bold">Nas Report</h6>
            </div>
            <div className="flex items-center lg:w-6/12 justify-center space-x-4 mr-4">
              <div className="relative lg:w-6/12 mr-4">
                <Select name="clist" options={companies} value={selectedOption} onChange={handleChange} placeholder="Select Entity" />
              </div>
              <div className="relative lg:w-6/12">
                <DatePicker
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => setDateRange(update)}
                  isClearable
                  placeholderText="Select Date Range"
                  className="w-full lg:w-auto mb-3 px-3 py-2 border rounded"
                />
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <div className="flex items-center space-x-2">
                <Select
                  options={formatOptions}
                  onChange={(option) => setSelectedFormat(option.value)}
                  placeholder="Select format"
                  className="w-32 text-sm mr-2"
                />

                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs mr-2 px-4 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none transition-all duration-150 flex items-center"
                  type="button"
                  onClick={() => selectedFormat && handleDownload(selectedFormat)}
                  disabled={!selectedFormat}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </button>
              </div>
              <Link to="/admin/reports/nasForm">
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none transition-all duration-150 flex items-center space-x-1"
                  type="button"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto py-24 lg:pt-0" key={companyData}> 
          {/* Nas Report list */}
          {companyData ? <NasReportList companyId={companyData} setNasData={setNasData} /> :''}
        </div> 
      </div>
    </main>
  );
}
