import React, { useState } from "react";
import LoadingSpinner from "../../../../components/Loadder"; 
import { postData } from "../../../../serverRequest";

export default function NasReportForm(props) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [entityName, setEntityName] = useState("");  
  const [hostName, setHostName] = useState("");  
  const [nasUrl, setNasUrl] = useState("");
  const [nasUser1, setNasUser1] = useState("");
  const [nasUser1Password, setNasUser1Password] = useState("");
  const [nasUser2, setNasUser2] = useState("");
  const [nasUser2Password, setNasUser2Password] = useState("");
  const [quickConnectId, setQuickConnectId]  = useState("");  
  const [quickConnectUrl, setQuickConnectUrl]  = useState("");
  const [modelNumber, setModelNumber]  = useState("");
  const [serialNumber, setSerialNumber]  = useState("");
  const [totalSpace, setTotalSpace]  = useState("");
  const [usedSpace, setUsedSpace]  = useState("");
  const [raidType, setRaidType] = useState("");
  const [numberHdd, setNumberHdd]  = useState("");
  const [serverHostName, setServerHostName]  = useState("");
  const [taskName, setTaskName]  = useState("");
  const [taskScheduleTime, setTaskScheduleTime]  = useState("");
  
  const handleSubmit = async (event) => {
    event.preventDefault();  
    setLoading(true);
  
    const formData = {
      'entity_name': entityName ? entityName : '',
      'host_name': hostName ? hostName : '',
      'nas_url': nasUrl ? nasUrl : '',
      'nas_user1': nasUser1 ? nasUser1 :'',
      'nas_user1_password': nasUser1Password ? nasUser1Password : '',
      'nas_user2': nasUser2 ? nasUser2 :'',
      'nas_user2_password': nasUser2Password ? nasUser2Password : '',
      'quick_connect_id': quickConnectId ? quickConnectId : '',
      'quick_connect_url': quickConnectUrl ? quickConnectUrl : '',
      'model_number': modelNumber ? modelNumber : '',
      'serial_number': serialNumber ? serialNumber : '',
      'total_space': totalSpace ? totalSpace : '',
      'used_space': usedSpace ? usedSpace : '',
      'raid_type': raidType ? raidType : '',
      'number_hdd': numberHdd ? numberHdd : '',
      'server_host_name': serverHostName ? serverHostName : '',
      'task_name': taskName ? taskName : '',
      'task_schedule_time': taskScheduleTime ? taskScheduleTime : '',
    };
  
    try {
      console.log(formData)
      const res = await postData('/api/product/nasdetail/', formData);
      console.log(res);
      if (res['data']['code'] === 200) {
        setLoading(false);
        setShowModal(true);
        alert('Nas report submitted successfully');
        window.location.href = '/admin/reports/nasReport';
      } else {
        alert('Nas report submission failed');
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };  
  
  return (
    <div className="nas-report-form-container p-4 pt-0 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">NAS Report Form</h6>
              </div>
            </div>            
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">  
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Entity Name</label>
                  <input type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name= "entityName" defaultValue={entityName} onChange={(e) => setEntityName(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Host Name</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="hostName" defaultValue={hostName} onChange={(e) => setHostName(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">URL to access NAS</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="nasUrl" defaultValue={nasUrl} onChange={(e) => setNasUrl(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">NAS User 1</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="nasUser1" defaultValue={nasUser1} onChange={(e) => setNasUser1(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">NAS User 1 Password</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="nasUser1Password" defaultValue={nasUser1Password} onChange={(e) => setNasUser1Password(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">NAS User 2</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="nasUser2" defaultValue={nasUser2} onChange={(e) => setNasUser2(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">NAS User 2 Password</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="nasUser2Password" defaultValue={nasUser2Password} onChange={(e) => setNasUser2Password(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Quick Connect ID</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="quickConnectId" defaultValue={quickConnectId} onChange={(e) => setQuickConnectId(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Quick Connect URL</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="quickConnectUrl" defaultValue={quickConnectUrl} onChange={(e) => setQuickConnectUrl(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Model No.</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="modelNumber" defaultValue={modelNumber} onChange={(e) => setModelNumber(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Serial No.</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="serialNumber" defaultValue={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Total Space</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="totalSpace" defaultValue={totalSpace} onChange={(e) => setTotalSpace(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Used Space</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="usedSpace" defaultValue={usedSpace} onChange={(e) => setUsedSpace(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">No. of HDD</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="numberHdd" defaultValue={numberHdd} onChange={(e) => setNumberHdd(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">RAID Type </label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="raidType" defaultValue={raidType} onChange={(e) => setRaidType(e.target.value)} />
                </div>
              </div>             
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Server Host Name</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="serverHostName" defaultValue={serverHostName} onChange={(e) => setServerHostName(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Task Name</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="taskName" defaultValue={taskName} onChange={(e) => setTaskName(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Task Schedule Time</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="taskScheduleTime" defaultValue={taskScheduleTime} onChange={(e) => setTaskScheduleTime(e.target.value)} />
                </div>
              </div>
            </div>       
            <div className="text-center mt-6 lg:w-3/12 px-4">
              <input
                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                type="Submit"
                value="Submit"
              />                            
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
