import axios from 'axios';
// var serverUrl = 'http://localhost:8000';
var serverUrl = 'https://d28ew0tj84ewtk.cloudfront.net';

const clearCookies = () => {
  // Get all cookies by splitting the document.cookie string by ';'
  const cookies = document.cookie.split(';');

  // Loop through each cookie and set its expiration date to the past
  cookies.forEach(cookie => {
      const cookieName = cookie.trim().split('=')[0];
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
};

export async function getData(url) {
  let requesturl = serverUrl + url;
  let response_data = {
    'code': 500,
    'message': "There is some issue in request",
    'data': ''
  }
  let token = '';
  const cookies = document.cookie.split(';');
  //console.log(cookies);
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('token=')) {
      token = cookie.substring(6);
    }
  }
  //console.log(token);
  await axios.get(requesturl, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
      // Add other headers as needed
    }
  })
    .then(res => {
      response_data['code'] = 200;
      response_data['data'] = res.data;
    }).catch(async function (error) {
      if (error.response) {
        if(error.response.status===403){
            await clearCookies();
            window.location.href = '/';
        }
      } else if (error.request) {
          console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    })
  return response_data
}

export async function postData(url, requested_data) {
  let response_data = {
    'code': 500,
    'message': "There is some issue in request",
    'data': ''
  }
  let requesturl = serverUrl + url;
  let token = '';
  //const cookiest = document.cookie;
  const cookies = document.cookie.split(';');
  //console.log(cookies);
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('token=')) {
      token = cookie.substring(6);
    }
  }
  //console.log(token);
  await axios.post(requesturl, requested_data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
      // Add other headers as needed
    }
  })
    .then(res => {
      response_data['code'] = 200;
      response_data['data'] = res.data;
    }).catch(error => {
      console.log(error)
    })
  return response_data
  //return response_data
}

export async function postFormData(url, requested_data) {
  let response_data = {
    'code': 500,
    'message': "There is some issue in request",
    'data': ''
  }
  let requesturl = serverUrl + url;
  let token = '';
  //const cookiest = document.cookie;
  const cookies = document.cookie.split(';');
  //console.log(cookies);
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('token=')) {
      token = cookie.substring(6);
    }
  }
  //console.log(token);
  await axios.post(requesturl, requested_data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': token,
      // Add other headers as needed
    }
  })
    .then(res => {
      response_data['code'] = 200;
      response_data['data'] = res.data;
    }).catch(error => {
      console.log(error)
    })
  return response_data

}

export async function getCompanyList(url) {
  let requesturl = serverUrl + '/api/company';
  let response_data = {
    'code': 500,
    'message': "There is some issue in request",
    'data': ''
  }
  let token = '';
  const cookies = document.cookie.split(';');
  //console.log(cookies);
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('token=')) {
      token = cookie.substring(6);
    }
  }
  //console.log(token);
  await axios.get(requesturl, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
      // Add other headers as needed
    }
  })
    .then(res => {
      let companyList = []
      if(res.data){
        for(let i=0;i<res.data.entities.length;i++){
          companyList.push({
            value: res.data.entities[i].id,
            label: res.data.entities[i].name
          })
        }
      }
      response_data['code'] = 200;
      response_data['data'] = companyList;
    }).catch(async function (error) {
      if (error.response) {
        if(error.response.status===403){
            await clearCookies();
            window.location.href = '/';
        }
      } else if (error.request) {
          console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    })
  return response_data
}