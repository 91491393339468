import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getData } from "../../../../serverRequest.js";
import LoadingSpinner from "../../../../components/Loadder/index.js";
import moment from 'moment';
import NasReportListTable from "../../../../components/Tables/NasReportListTable.js";
// components

export default function NasReportList(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const companyID = props.companyId;
  const [open, setOpen] = useState(true);
  const [selectedCompanyId, setSelectedCompanyId] = useState(companyID);
  const [nasDetailId, setNasDetailId] = useState([]);
  const [totalNas, setTotalNas] = useState([]);
  const [loading, setLoading] = useState(true);

  const nasFilter = searchParams.get('filter');
  console.log(props);
  
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
  };

  const filterNasData = (nas_data) => {
    console.log(nas_data);
    let totalnas = [];
    if(nas_data){
      for (let i = 0; i < nas_data.length; i++) {
          totalnas.push(nas_data[i])
      }
    }
         
    setTotalNas(totalnas);
    return ({ "total": totalNas })
  }
    
  useEffect(() => {
    async function fetchData() {
      await setSelectedCompanyId(selectedCompanyId);
      if (selectedCompanyId) {
        let resData = await getData('/api/product/nasdetail?nasdetail_id=' + nasDetailId)
        let nas_data = resData['data']['nasdetail_data']
        console.log(nas_data);
        let nasData = await filterNasData(nas_data)
      }
      else if (nasFilter){
        let resData = await getData('/api/product/?admin=1&nas_filter=' + nasFilter)
        let nas_data = resData['data']['nasdetail_data']
        //console.log(tickets_data);
        let nasData = await filterNasData(nas_data)        
      }
      else {
        let resData = await getData('/api/product/?admin=1')
        let nas_data = resData['data']['nasdetail_data']
        //console.log(tickets_data);
        let nasData = await filterNasData(nas_data)
      }
  
      setLoading(false);
    }
    fetchData();
  
  }, [])

  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (<>
        <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +("light" === "light" ? "bg-white" : "bg-lightBlue-900 text-white")}>
          <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4" key={selectedCompanyId}>
            {/* NAS Report table */}
            <NasReportListTable totalNas={totalNas}></NasReportListTable>
          </div>
        </div>
      </>)}
    </>
  );
}

NasReportList.defaultProps = {
  color: "light",
};

NasReportList.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
