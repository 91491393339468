import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../../../components/Loadder"; 
import { getData, postData } from "../../../../serverRequest";

export default function ProductReportData(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const bizproductId = searchParams.get('bizproduct_id');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [entityName, setEntityName] = useState("");  
  const [location, setLocation] = useState("");  
  const [oem, setOem] = useState("");
  const [productType, setProductType] = useState("");
  const [userName, setUserName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [memoryFrequency, setMemoryFrequency]  = useState("");
  const [memorySlot, setMemorySlot] = useState("");
  const [processor, setProcessor] = useState("");
  const [storage, setStorage] = useState("");
  const [storageType, setStorageType] = useState("");
  const [macAddress, setMacAddress] = useState("");
  const [raidType, setRaidType] = useState("");
  const [operatingSystem, setOperatingSystem] = useState("");
  const [vendor, setVendor] = useState("");
  const [purchasingDate, setPurchasingDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [price, setPrice] = useState("");
  const [healthStatus, setHealthStatus] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  
  const handleSubmit = async (event) => {  
    event.preventDefault();
    setLoading(true);
    
    const formData = {
      'id': bizproductId ? bizproductId : '',
      'entity_name': entityName ? entityName : '',
      'location': location ? location : '',
      'oem': oem ? oem : '',
      'product_type': productType ? productType :'',
      'user_name': userName ? userName : '',
      'serial_number': serialNumber ? serialNumber :'',
      'model_number': modelNumber ? modelNumber : '',
      'memoryFrequency': memoryFrequency ? memoryFrequency : '', 
      'memory_slot': memorySlot ? memorySlot : '',
      'processor': processor ? processor : '',
      'storage': storage ? storage : '',
      'storage_type': storageType ? storageType : '',
      'mac_address': macAddress ? macAddress : '',
      'raid_type': raidType ? raidType : '',
      'operating_system': operatingSystem ? operatingSystem : '',
      'vendor': vendor ? vendor : '',
      'purchasing_date': purchasingDate ? purchasingDate : '',
      'invoice_number': invoiceNumber ? invoiceNumber : '',
      'price': price ? price : '',
      'health_status': healthStatus ? healthStatus : '',
    };
    
    try {
      console.log(formData);
      const res = await postData('/api/product/bizproduct/', formData); 
      console.log(res);
      if (res['data']['code'] === 200) {
        setLoading(false);
        setShowModal(true);
        alert('Product report submitted successfully');
        window.location.href = '/admin/reports/productReport'; 
      } else {
        alert('Product report submission failed');
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    } 
  };   

  useEffect(() => {
    async function fetchData() {
      let res = await getData('/api/product/bizproduct?bizproduct_id=' + bizproductId)
      let product_data = res['data']['bizproduct_data']
      console.log(product_data);
      if(product_data){
        setEntityName(product_data['entity_name']);
        setLocation(product_data['location']);
        setOem(product_data['oem']);
        setProductType(product_data['product_type']);
        setUserName(product_data['user_name']);
        setSerialNumber(product_data['serial_number']);
        setModelNumber(product_data['model_number']);  
        setMemoryFrequency(product_data['memory_frequency']);
        setMemorySlot(product_data['memory_slot']);
        setProcessor(product_data['processor']);
        setStorage(product_data['storage']);
        setStorageType(product_data['storage_type']);
        setMacAddress(product_data['mac_address']);
        setRaidType(product_data['raid_type']);
        setOperatingSystem(product_data['operating_system']);
        setVendor(product_data['vendor']);
        setPurchasingDate(product_data['purchasing_date']);
        setInvoiceNumber(product_data['invoice_number']);
        setPrice(product_data['price']);
        setHealthStatus(product_data['health_status']);
      }
         
      setLoading(false);
    }
    fetchData();    
    
  }, [])
  
  return (
    <div className="product-report-data-container p-4 pt-0 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Product Report ID : {bizproductId}</h6>
                <button 
                  className={`cursor-pointer px-0 py-0 text-xl mr-2 leading-none bg-transparent hover:outline-none focus:outline-none rounded ${ isEditable ? "opacity-100 text-blue-600" : "opacity-30 text-blueGray-500" }`}
                  type="button" onClick={() => setIsEditable(isEditable ? false : true)}
                >
                  <i className={`fas fa-edit text-xl hover:outline-none focus:outline-none ${ isEditable ? 'text-lightBlue-500' : 'text-blueGray-400' }`}></i>
                </button>
              </div>
            </div>            
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">  
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Entity Name</label>
                  <input type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled
                    name= "entityName" value={entityName} onChange={(e) => setEntityName(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Location</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="location" value={location} onChange={(e) => setLocation(e.target.value)} />
                </div>
              </div>
                <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">OEM</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="oem" value={oem} onChange={(e) => setOem(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Product Type</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="productType" value={productType} onChange={(e) => setProductType(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">User Name</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="userName" value={userName} onChange={(e) => setUserName(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Serial Number</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="serialNumber" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Model Number</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="modelNumber" value={modelNumber} onChange={(e) => setModelNumber(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Memory(Frequency)</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="memoryFrequency" value={memoryFrequency} onChange={(e) => setMemoryFrequency(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Memory Slot</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="memorySlot" value={memorySlot} onChange={(e) => setMemorySlot(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Processor</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="processor" value={processor} onChange={(e) => setProcessor(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Storage</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="storage" value={storage} onChange={(e) => setStorage(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Storage Type</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="storageType" value={storageType} onChange={(e) => setStorageType(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">MAC Address</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="macAddress" value={macAddress} onChange={(e) => setMacAddress(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">RAID Type</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="raidType" value={raidType} onChange={(e) => setRaidType(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Operating System</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="operatingSystem" value={operatingSystem} onChange={(e) => setOperatingSystem(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Vendor</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="vendor" value={vendor} onChange={(e) => setVendor(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Purchasing Date</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="purchasingDate" value={purchasingDate} onChange={(e) => setPurchasingDate(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Invoice Number</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="invoiceNumber" value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Price</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="price" value={price} onChange={(e) => setPrice(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Health Status</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="healthStatus" value={healthStatus} onChange={(e) => setHealthStatus(e.target.value)} />
                </div>
              </div>
            </div>
            {isEditable && (
              <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                  type="Submit"
                  value="Submit"
                />                            
              </div>
            )}
          </div>
        </form>
      )}
    </div>
  );
}
