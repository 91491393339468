import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getData } from "../../../../serverRequest.js";
import LoadingSpinner from "../../../../components/Loadder/index.js";
import moment from 'moment';
import ProductReportListTable from "../../../../components/Tables/ProductReportListTable.js";
// components

export default function ProductReportList(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const companyID = props.companyId;
  const [open, setOpen] = useState(true);
  const [selectedCompanyId, setSelectedCompanyId] = useState(companyID);
  const [bizproductId, setBizproductId] = useState([]);
  const [totalProduct, setTotalProduct] = useState([]);
  const [loading, setLoading] = useState(true);

  const productFilter = searchParams.get('filter');
  console.log(props);
  
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
  };

  const filterProductData = (product_data) => {
    console.log(product_data);
    let totalproduct = [];
    if(product_data){
      for (let i = 0; i < product_data.length; i++) {
          totalproduct.push(product_data[i])
      }
    }
         
    setTotalProduct(totalproduct);
    return ({ "total": totalProduct })
  }
    
  useEffect(() => {
    async function fetchData() {
      await setSelectedCompanyId(selectedCompanyId);
      if (selectedCompanyId) {
        let resData = await getData('/api/product/bizproduct?company_id=' + selectedCompanyId)
        let product_data = resData['data']['bizproduct_data']
        console.log(product_data);
        let productData = await filterProductData(product_data)
      }
      else if (productFilter){
        let resData = await getData('/api/product/?admin=1&product_filter=' + productFilter)
        let product_data = resData['data']['bizproduct_data']
        //console.log(tickets_data);
        let productData = await filterProductData(product_data)        
      }
      else {
        let resData = await getData('/api/product/?admin=1')
        let product_data = resData['data']['bizproduct_data']
        //console.log(tickets_data);
        let productData = await filterProductData(product_data)
      }
  
      setLoading(false);
    }
    fetchData();
  
  }, [companyID])

  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (<>
        <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +("light" === "light" ? "bg-white" : "bg-lightBlue-900 text-white")}>
          <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4" key={selectedCompanyId}>
            {/* Product Report table */}
            <ProductReportListTable totalProduct={totalProduct}></ProductReportListTable>
          </div>
        </div>
      </>)}
    </>
  );
}

ProductReportList.defaultProps = {
  color: "light",
};

ProductReportList.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
