import React from 'react';
import moment from 'moment';

//Bootstrap and jQuery libraries
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const formatDate = (dateString) => {
  return moment(dateString).format('DD/MM/YYYY');
};

const formatEmployeeId = (employeeId) => {
  return employeeId.toUpperCase();
};

class UserAttendanceApprovalTable extends React.Component {
  componentDidMount() {

    //initialize datatable
    $(document).ready(function () {
      var sort_col = $('#userAttendanceApproval').find("th:contains('ID')")[0].cellIndex;
      $('#userAttendanceApproval').DataTable({
        order: [[sort_col, 'desc']],
        columnDefs: [
          {
            target: 0,
            visible: false,
            searchable: false
          }
        ],
      });
    });
  }
  render() {
    //Datatable HTML
    return (
      <table id="userAttendanceApproval" class="items-center w-full bg-transparent border-collapse">
        
        <thead>
          <tr>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              ID
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              Employee ID
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                ("light" === "light"
                  ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
              }
            >
              Employee Name
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              Total Working Days
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              Total Leaves
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                ("light" === "light"
                  ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
              }
            >
              Date Range
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              Leave Applied
            </th>
            <th
              className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                " bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              }
            >
              Approve/Reject
            </th>
          </tr>
        </thead>

        <tbody>
          {this.props.attendanceApproval.map((item, index) => (
            <tr>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.id}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a style={{ "light": '#0074cd' }} href={'/user/ticket/?ticket_id=' + item.employee_id}>{formatEmployeeId(item.employee_id)}</a>
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.employee_name}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.total_working_days}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.total_leaves}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.date_range}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" 
                  style={{backgroundColor:                 
                    item.status === 'Assigned' ? '#E0F2FE' :
                    item.status === 'IN Progress' ? '#E6E6FA' :
                    item.status === 'Approval Pending' ? '#FFF9CC' :
                    item.status === 'Approved' ? '#FFEFD5' :
                    item.status === 'Rejected' ? '#FADBD8' :
                    item.status === 'Resolved' ? '#BDF3D8' :'#FFFFFF'}}>                
                  {item.status === 'Assigned' ? <i className="fas fa-circle mr-4" style={{color: '#5BC0DE'}}></i> :
                    item.status === 'IN Progress' ? <i className="fas fa-circle mr-4" style={{color: '#800080'}}></i> :
                    item.status === 'Approval Pending' ? <i className="fas fa-circle mr-4" style={{color: '#FFFF00'}}></i> :
                    item.status === 'Approved' ? <i className="fas fa-circle mr-4" style={{color: '#FFA500'}}></i> :
                    item.status === 'Rejected' ? <i className="fas fa-circle mr-4" style={{color: '#FF0000'}}></i> :
                    item.status === 'Resolved' ? <i className="fas fa-circle mr-4" style={{color: '#00FF00'}}></i> :                
                  <i className="fas fa-circle mr-4" style={{color: '#D3D3D3'}}></i>}{item.leave_applied}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {item.approve_reject}
              </td>
            </tr>
          ))}
        </tbody>

      </table>
    );
  }
}
export default UserAttendanceApprovalTable;