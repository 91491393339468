import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getData } from "../../../../serverRequest.js";
import LoadingSpinner from "../../../../components/Loadder/index.js";
import moment from 'moment';
import IspReportListTable from "../../../../components/Tables/IspReportListTable.js";
// components

export default function IspReportList(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const companyID = props.companyId;
  const [open, setOpen] = useState(true);
  const [selectedCompanyId, setSelectedCompanyId] = useState(companyID);
  const [ispDetailId, setIspDetailId] = useState([]);
  const [totalIsp, setTotalIsp] = useState([]);
  const [loading, setLoading] = useState(true);

  const ispFilter = searchParams.get('filter');
  console.log(props);
  
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
  };

  const filterIspData = (isp_data) => {
    console.log(isp_data);
    let totalisp = [];
    if(isp_data){
      for (let i = 0; i < isp_data.length; i++) {
          totalisp.push(isp_data[i])
      }
    }
       
    setTotalIsp(totalisp);
    return ({ "total": totalIsp })
  }
  
  useEffect(() => {
    async function fetchData() {
      await setSelectedCompanyId(selectedCompanyId);
      if (selectedCompanyId) {
        let resData = await getData('/api/product/ispdetail?company_id=' + selectedCompanyId)
        let isp_data = resData['data']['ispdetail_data']
        console.log(isp_data);
        let ispData = await filterIspData(isp_data)
      }
      else if (ispFilter){
        let resData = await getData('/api/product/ispdetail?admin=1&isp_filter=' + ispFilter)
        let isp_data = resData['data']['ispdetail_data']
        //console.log(tickets_data);
        let ispData = await filterIspData(isp_data)        
      }
      else {
        let resData = await getData('/api/product/ispdetail?admin=1')
        let isp_data = resData['data']['ispdetail_data']
        //console.log(tickets_data);
        let ispData = await filterIspData(isp_data)
      }

      setLoading(false);
    }
    fetchData();

  }, [companyID])

  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (<>
        <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +("light" === "light" ? "bg-white" : "bg-lightBlue-900 text-white")}>
          <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4" key={selectedCompanyId}>
            {/* ISP Report table */}
            <IspReportListTable totalIsp={totalIsp}></IspReportListTable>
          </div>
        </div>
      </>)}
    </>
  );
}

IspReportList.defaultProps = {
  color: "light",
};

IspReportList.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
