import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { toast, ToastContainer } from 'react-toastify';
import { getData, postData } from '../../../serverRequest.js';
import moment from 'moment';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import AttendanceToolbar from './AttendanceToolbar.js';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-toastify/dist/ReactToastify.css';
import '../../../AttendanceCalendar.css';
import { status } from '../../../components/data/data.js';

export default function UserAttendanceCalendar() {
  const [events, setEvents] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentSlot, setCurrentSlot] = useState(null);
  const [attendance, setAttendance] = useState('');
  const [loginTime, setLoginTime] = useState('');
  const [logoutTime, setLogoutTime] = useState('');
  const [remarks, setRemarks] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const searchParams = new URLSearchParams(window.location.search);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    const fetchAttendanceData = async () => {
      // try {
      const apiData = await getData('/api/employee/attendance_record/?employee_id=' + localStorage.getItem('employeeId'));
      console.log(apiData['data']['attendance_data'])
      if (apiData['data']['attendance_data']) {
        const formattedEvents = apiData['data']['attendance_data'].map(event => ({
          start: new Date(event.date),
          end: new Date(event.date),
          allDay: false,
          className: 'custom-event',
          status: event.status,
          title: event.status === 'P' ? 'Present' : event.status === 'A' ? 'Absent':event.status === 'H' ? 'Half Day': event.title,
        }));
        setEvents(formattedEvents);
      }
      // } catch (error) {
      //   toast.error('Failed to load attendance data.');
      // }
    };

    fetchAttendanceData();
  }, []);

  const saveAttendanceData = async (updatedEvents) => {

    console.log(attendance);

    let reqData = {
      employee_id: localStorage.getItem('employeeId'),
      date: moment(currentSlot.start).format('YYYY-MM-DD'),
      end: moment(currentSlot.end).format('YYYY-MM-DD'),
      remarks: remarks,
      status: attendance,
    }
    console.log(reqData);
    try {
      let resData = await postData('/api/employee/attendance_record/', reqData);
      if (resData['data']['code'] === 200) {
        toast.success('Attendance data saved successfully.');
      }
    } catch (error) {
      console.error('Error saving attendance data:', error);
      toast.error('Failed to save attendance data.');
    }
  };

  const handleSaveAttendance = () => {
    // if (attendance === 'P' || attendance === 'H') {
    //   if (!loginTime || !logoutTime) {
    //    toast.error('Please enter login and logout times for Present or Half Day attendance.');
    //    return;
    //  }
    // }

    if (isEditing && !remarks) {
      toast.error('Remarks are required when editing.');
      return;
    }

    if (attendance === 'P' || attendance === 'A' || attendance === 'H' || attendance === 'L') {
      const title = attendance === 'P' ? 'Present' : attendance === 'A' ? 'Absent' : attendance === 'L' ? 'Leave' : 'Half Day';
      // const formattedLoginTime = attendance !== 'A' ? `Login: ${moment(loginTime, 'hh:mm A').format('hh:mm A')}` : '';
      // const formattedLogoutTime = attendance !== 'A' ? `Logout: ${moment(logoutTime, 'hh:mm A').format('hh:mm A')}` : '';
      const description = `${title}`; //\n${formattedLoginTime}\n${formattedLogoutTime}`;

      let updatedEvents;
      if (isEditing && editingEvent) {
        updatedEvents = events.map(event =>
          event === editingEvent ? { ...event, title: description, remarks } : event
        );
      } else {
        updatedEvents = [
          ...events,
          {
            start: currentSlot.start,
            end: currentSlot.end,
            title: description,
            allDay: true,
            className: 'custom-event',
            remarks,
          },
        ];
      }

      setEvents(updatedEvents);
      saveAttendanceData(updatedEvents); // Save updated events to the backend
      handleCloseModal();
    } else {
      toast.error('Invalid input. Please select Present, Half Day, Absent or Leave.');
    }
  };

  const localizer = momentLocalizer(moment);
  Modal.setAppElement('#root');

  const isValidDate = (date) => {
    const today = moment();
    const pastSevenDays = today.clone().subtract(7, 'days');

    if (moment(date).isBetween(pastSevenDays, today, 'day', '[]')) {
      return false; // Valid for the past 7 days and today (inclusive)
    }
    // All other dates are disabled
    return true;
  };

  const handleSelectSlot = ({ start, end }) => {
    if (isValidDate(start)) {
      toast.error('Cannot mark attendance beyond previous week and today.');
      return;
    }
    setCurrentSlot({ start, end });
    setModalIsOpen(true);
    setIsEditing(false);
    setAttendance('');
    setLoginTime('');
    setLogoutTime('');
    setRemarks('');
  };

  const handleSelectEvent = (event) => {
    if (isValidDate(event.start)) {
      toast.error('Cannot edit attendance beyond previous week and today.');
      return;
    }
    if(event.title){
    const attendance = event.title.includes('Present') ? 'P' : event.title.includes('Absent') ? 'A' : 'H';
    const loginTime = event.title.match(/Login: (\d{1,2}:\d{2}) (AM|PM)/)?.[0] || '';
    const logoutTime = event.title.match(/Logout: (\d{1,2}:\d{2}) (AM|PM)/)?.[0] || '';
    setCurrentSlot({ start: event.start, end: event.end });
    setAttendance(attendance);
    setLoginTime(loginTime);
    setLogoutTime(logoutTime);
    setRemarks(event.remarks || '');
    setIsEditing(true);
    setEditingEvent(event);
    setModalIsOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setAttendance('');
    setLoginTime('');
    setLogoutTime('');
    setRemarks('');
    setCurrentSlot(null);
    setIsEditing(false);
    setEditingEvent(null);
  };

  const eventPropGetter = (event) => {
    let bgColor = '';
    if(event.title){
      bgColor = event.title.includes('Present')
      ? 'bg-green-500'
      : event.title.includes('Absent')
        ? 'bg-red-500'
        : event.title.includes('Leave')
          ? 'bg-blue-500'
          : 'bg-yellow-500';
    }


    return {
      className: `custom-event text-white ${bgColor} p-1 rounded relative`,
      style: { whiteSpace: 'pre-wrap' },
    };
  };

  const EventComponent = ({ event }) => (
    <div className="flex items-center justify-between">
      {event.title ? (
      <div dangerouslySetInnerHTML={{ __html: event.title.replace(/\n/g, '<br>') }} />
      ) : ''}
    </div>
  );

  const handleDownload = (type) => {
    const data = events.map(event => ({
      Date: moment(event.start).format("YYYY-MM-DD"),
      Attendance: event.title,
      Remarks: event.remarks || "N/A",
    }));

    if (type === "csv") {
      const csvData = [
        ["Date", "Attendance", "Remarks"],
        ...data.map(row => [row.Date, row.Attendance, row.Remarks])
      ];
      const csvString = csvData.map(row => row.join(",")).join("\n");
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, "user_attendance_report.csv");
    } else if (type === "xlsx") {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Attendance");
      XLSX.writeFile(workbook, "user_attendance_report.xlsx");
    }
  };

  return (
    <div className="m-5 p-0 pt-0 bg-gray-100 min-h-screen w-full">
      <div className="rounded bg-white px-6 py-6">
        <div className="text-center flex justify-between">
          <h6 className="text-blueGray-700 text-xl py-1 font-bold">Attendance</h6>
          <div className="w-full lg:w-3/12 px-4">
            <div className="relative w-">
              <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => setDateRange(update)}
                isClearable
                placeholderText="Select Date Range"
                className="w-full lg:w-3/12 mb-3 px-3 py-2 border rounded"
              />
            </div>
          </div>
          <div>
            <button
              onClick={() => handleDownload("csv")}
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-3 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
            >
              Download CSV
            </button>
            <button
              onClick={() => handleDownload("xlsx")}
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-3 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
            >
              Download Excel
            </button>
          </div>
        </div>
      </div>
      <div className='mt-2 p-2 bg-white rounded shadow'>
        <Calendar
          localizer={momentLocalizer(moment)}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 550 }}
          selectable
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          eventPropGetter={eventPropGetter}
          components={{
            event: EventComponent,
            toolbar: AttendanceToolbar,
          }}
        />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Attendance Input"
        >
          <div className="modal-content px-3 py-2">
            <h2 className="text-blueGray-700 text-xl font-bold text-center">{isEditing ? 'Edit Attendance' : 'Mark Attendance'}</h2>
            <label className="block uppercase text-blueGray-600 text-sm font-bold mb-2" htmlFor="enter-attendance">
              Attendance :
              <select
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value={attendance}
                onChange={e => setAttendance(e.target.value)}>
                <option value="">Select Attendance</option>
                <option value="P">Present</option>
                <option value="A">Absent</option>
                <option value="H">Half Day</option>
                <option value="L">Leave</option>
              </select>
            </label>
            {/* {attendance !== 'A' && (
              <>
                <label className="block uppercase text-blueGray-600 text-sm font-bold mb-2" htmlFor="login-time">
                  Login time :
                  <input
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    type="time"
                    value={loginTime}
                    onChange={(e) => setLoginTime(e.target.value)}
                  />
                </label>
                <label className="block uppercase text-blueGray-600 text-sm font-bold mb-2" htmlFor="logout-time">
                  Logout time :
                  <input
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    type="time"
                    value={logoutTime}
                    onChange={(e) => setLogoutTime(e.target.value)}
                  />
                </label>
              </>
            )} */}
            <label className="block uppercase text-blueGray-600 text-sm font-bold mb-2">
              Remarks:
              <textarea
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value={remarks}
                onChange={e => setRemarks(e.target.value)}
              />
            </label>
            <button onClick={handleSaveAttendance}
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow 
                                 hover:shadow-lg outline-none focus:outline-none mr-1 mt-2 mb-1 w-full ease-linear transition-all duration-150">Save</button>
            <button onClick={handleCloseModal}
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow 
                                 hover:shadow-lg outline-none focus:outline-none mr-1 mt-2 mb-1 w-full ease-linear transition-all duration-150">Cancel</button>
          </div>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};
