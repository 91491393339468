/*eslint-disable*/
import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from '@headlessui/react';
import testimonial from '../../assets/img/testimonial.png';
import logo from '../../assets/img/logo.png';
import { getData, postData } from "../../serverRequest";
// components
import { Fragment } from 'react'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function UserNavbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [userName, setUserName] = React.useState(localStorage.getItem('userName'));
  const [companyApprover, setCompanyApprover] = React.useState(localStorage.getItem('companyApprover'));
  const [ticketsOpen, setTicketsOpen] = React.useState(false);
  const [attendanceOpen, setAttendanceOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [companyId, setCompanyId] = useState(localStorage.getItem('companyId'));

  const handleLogout = async () => {
    // Clear the cookies

    let resData = await getData('/api/logout/');
    if (resData['data']['code'] === 200) {
      await clearCookies();
      alert('You have been logged out.');
      window.location.href = '/';
    }

  };

  const clearCookies = () => {
    // Get all cookies by splitting the document.cookie string by ';'
    const cookies = document.cookie.split(';');

    // Loop through each cookie and set its expiration date to the past
    cookies.forEach(cookie => {
      const cookieName = cookie.trim().split('=')[0];
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  };

  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/user"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            >
              <img src={logo} width='120px'  ></img>
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none mr-auto">
              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="/user"
                >
                  Dashboard
                </a>
              </li>
              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="/user/serviceType"
                >
                  New Request
                </a>
              </li>
              <li className="relative md:min-w-fit flex items-center hover:text-blueGray-500 text-blueGray-700 text-xs uppercase font-bold">
                <Menu as="div">                  
                  <Menu.Button className="pl-4 pr-4 uppercase font-bold block focus:outline-none">    
                    Tickets
                  </Menu.Button>                 
                  <Transition
                    as="div"
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >                
                  <Menu.Items style={{ width: '9rem' }} className="absolute right-1 z-10 mt-2 origin-top-right rounded border bg-white py-0 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item className="flex items-center">
                      <a
                        className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                        href="/user/userSolutions"
                      >
                        My Tickets
                      </a>
                    </Menu.Item>
                    {companyApprover === 'true' ? (
                      <>
                      <Menu.Item className="flex items-center">
                        <a
                          className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          href="/user/userApprovals"
                        >
                          Approval Tickets
                        </a>
                      </Menu.Item>
                      <Menu.Item className="flex items-center">
                        <a
                          className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          href="/user/companyTickets"
                        >
                          Company Tickets
                        </a>
                      </Menu.Item>
                    </>
                    ) : ''}
                  </Menu.Items> 
                  </Transition>               
                </Menu>
              </li> 
              {(companyId === '15' || companyId === 15 || companyId === '1' || companyId === 1) ? (
              <li className="relative md:min-w-fit flex items-center hover:text-blueGray-500 text-blueGray-700 text-xs uppercase font-bold">
                <Menu as="div">                  
                  <Menu.Button className="pl-4 pr-4 uppercase font-bold block focus:outline-none">    
                    Attendance
                  </Menu.Button>                 
                  <Transition
                    as="div"
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >                
                  <Menu.Items style={{ width: '11rem' }} className="absolute right-1 z-10 mt-2 origin-top-right rounded border bg-white py-0 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">            
                    {companyApprover === 'false' ? (
                      <>
                      <Menu.Item className="flex items-center">
                        <a
                          className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          href="/user/attendance"
                        >
                          My Attendance
                        </a>
                      </Menu.Item>
                      </>
                    ) : ''}
                    {companyApprover === 'true' ? (
                      <>
                      <Menu.Item className="flex items-center">
                        <a
                          className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          href="/user/companyAttendance"
                        >
                          Company Attendance
                        </a>
                      </Menu.Item>
                      <Menu.Item className="flex items-center">
                        <a
                          className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          href="/user/attendanceApproval"
                        >
                          Attendance Approval
                        </a>
                      </Menu.Item>
                      </>
                    ) : ''}
                  </Menu.Items> 
                  </Transition>               
                </Menu>
              </li> ) : ''}
              {/* <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="/user/userFaq"
                >
                  Faq
                </a>
              </li> */}
            </ul>
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <p
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                >
                  {userName}
                </p>
              </li>
              <li className="flex items-center">
                <Menu as="div" className="relative ml-2">
                  <div>
                    <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={testimonial}
                        alt="Profile"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Profile
                          </a>
                        )}
                      </Menu.Item> 
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            onClick={handleLogout}
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Logout
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
