import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getData } from "../../../../serverRequest.js";
import LoadingSpinner from "../../../../components/Loadder/index.js";
import moment from 'moment';
import ServiceReportListTable from "../../../../components/Tables/ServiceReportListTable.js";
// components

export default function ServiceReportList(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const companyID = props.companyId;
  const [open, setOpen] = useState(true);
  const [selectedCompanyId, setSelectedCompanyId] = useState(companyID);
  const [productserviceId, setProductserviceId] = useState([]);
  const [totalService, setTotalService] = useState([]);
  const [loading, setLoading] = useState(true);

  const serviceFilter = searchParams.get('filter');
  console.log(props);
  
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
  };

  const filterServiceData = (service_data) => {
    console.log(service_data);
    let totalservice = [];
    if(service_data){
      for (let i = 0; i < service_data.length; i++) {
          totalservice.push(service_data[i])
      }
    }
       
    setTotalService(totalservice);
    return ({ "total": totalService })
  }
  
  useEffect(() => {
    async function fetchData() {
      await setSelectedCompanyId(selectedCompanyId);
      if (selectedCompanyId) {
        let resData = await getData('/api/product/productservice?company_id=' + selectedCompanyId)
        let service_data = resData['data']['productservice_data']
        console.log(service_data);
        let serviceData = await filterServiceData(service_data)
      }
      else if (serviceFilter){
        let resData = await getData('/api/product/?admin=1&service_filter=' + serviceFilter)
        let service_data = resData['data']['productservice_data']
        //console.log(tickets_data);
        let serviceData = await filterServiceData(service_data)        
      }
      else {
        let resData = await getData('/api/product/?admin=1')
        let service_data = resData['data']['productservice_data']
        //console.log(tickets_data);
        let serviceData = await filterServiceData(service_data)
      }
  
      setLoading(false);
    }
    fetchData();
  
  }, [companyID])

  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (<>
        <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +("light" === "light" ? "bg-white" : "bg-lightBlue-900 text-white")}>
          <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4" key={selectedCompanyId}>
            {/* Service Report table */}
            <ServiceReportListTable totalService={totalService}></ServiceReportListTable>
          </div>
        </div>
      </>)}
    </>
  );
}

ServiceReportList.defaultProps = {
  color: "light",
};

ServiceReportList.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
