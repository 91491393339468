import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../../../components/Loadder"; 
import { getData, postData } from "../../../../serverRequest";

export default function IspReportData(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const ispdetailId = searchParams.get('ispdetail_id');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [entityName, setEntityName] = useState("");  
  const [isp, setIsp] = useState("");  
  const [ipAddress, setIpAddress] = useState("");
  const [subnet, setSubnet] = useState("");
  const [gateway, setGateway] = useState("");
  const [dns1, setDns1] = useState("");
  const [dns2, setDns2] = useState("");
  const [bandwidth, setBandwidth]  = useState("");
  const [connectionType, setConnectionType]  = useState("");
  const [rdsPort, setRdsPort]  = useState("");
  const [routerUrl, setRouterUrl]  = useState("");
  const [routerUsername, setRouterUsername]  = useState("");
  const [routerPassword, setRouterPassword]  = useState("");
  const [dmzHost, setDmzHost]  = useState("");
  const [ssidName, setSsidName]  = useState("");  
  const [ssidPassword, setSsidPassword]  = useState("");
  const [apUrl, setApUrl]  = useState("");
  const [apUsername, setApUsername]  = useState("");
  const [apPassword, setApPassword]  = useState("");
  const [isEditable, setIsEditable] = useState(false);
  
  const handleSubmit = async (event) => {  
    event.preventDefault();
    setLoading(true);
    
    const formData = {
      'id': ispdetailId ? ispdetailId : '',
      'entity_name': entityName ? entityName : '',
      'isp': isp ? isp : '',
      'ip_address': ipAddress ? ipAddress : '',
      'subnet': subnet ? subnet :'',
      'gateway': gateway ? gateway : '',
      'dns_1': dns1 ? dns1 :'',
      'dns_2': dns2 ? dns2 : '',
      'bandwidth': bandwidth ? bandwidth : '',
      'connection_type': connectionType ? connectionType : '',
      'rds_port_number': rdsPort ? rdsPort : '',
      'router_url': routerUrl ? routerUrl : '',
      'router_username': routerUsername ? routerUsername : '',
      'router_password': routerPassword ? routerPassword : '',
      'dmz_host': dmzHost ? dmzHost : '',
      'ssid_name': ssidName ? ssidName : '',
      'ssid_password': ssidPassword ? ssidPassword : '',
      'ap_url': apUrl ? apUrl : '',
      'ap_username': apUsername ? apUsername : '',
      'ap_password': apPassword ? apPassword : '',
    };    
    
    try {
      console.log(formData);
      const res = await postData('/api/product/ispdetail/', formData); 
      console.log(res);
      if (res['data']['code'] === 200) {
        setLoading(false);
        setShowModal(true);
        alert('Isp report submitted successfully');
        window.location.href = '/admin/reports/ispReport';  
      } else {
        alert('Isp report submission failed');
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      } 
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };  

  useEffect(() => {
    async function fetchData() {
      let res = await getData('/api/product/ispdetail?ispdetail_id=' + ispdetailId)
      let isp_data = res['data']['ispdetail_data']
      console.log(isp_data);
      if(isp_data){
        setEntityName(isp_data['entity_name']);
        setIsp(isp_data['isp']);
        setIpAddress(isp_data['ip_address']);
        setSubnet(isp_data['subnet']);
        setGateway(isp_data['gateway']);
        setDns1(isp_data['dns_1']);
        setDns2(isp_data['dns_2']);  
        setBandwidth(isp_data['bandwidth']);
        setConnectionType(isp_data['connection_type']);
        setRdsPort(isp_data['rds_port_number']);
        setRouterUrl(isp_data['router_url']);
        setRouterUsername(isp_data['router_username']);
        setRouterPassword(isp_data['router_password']);
        setDmzHost(isp_data['dmz_host']);
        setSsidName(isp_data['ssid_name']);
        setSsidPassword(isp_data['ssid_password']);
        setApUrl(isp_data['ap_url']);
        setApUsername(isp_data['ap_username']);
        setApPassword(isp_data['ap_password']);
      }
             
      setLoading(false);
    }
    fetchData();        
        
  }, [])
  
  return (
    <div className="isp-report-data-container p-4 pt-0 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">ISP Report ID : {ispdetailId}</h6>
                <button 
                  className={`cursor-pointer px-0 py-0 text-xl mr-2 leading-none bg-transparent hover:outline-none focus:outline-none rounded ${ isEditable ? "opacity-100 text-blue-600" : "opacity-30 text-blueGray-500" }`}
                  type="button" onClick={() => setIsEditable(isEditable ? false : true)}
                >
                  <i className={`fas fa-edit text-xl hover:outline-none focus:outline-none ${ isEditable ? 'text-lightBlue-500' : 'text-blueGray-400' }`}></i>
                </button>
              </div>
            </div>            
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">  
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Entity Name</label>
                  <input type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled
                    name= "entityName" value={entityName} onChange={(e) => setEntityName(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">ISP</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="isp" value={isp} onChange={(e) => setIsp(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">IP Address</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="ipAddress" value={ipAddress} onChange={(e) => setIpAddress(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Subnet</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="subnet" value={subnet} onChange={(e) => setSubnet(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Gateway</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="gateway" value={gateway} onChange={(e) => setGateway(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">DNS 1</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="dns1" value={dns1} onChange={(e) => setDns1(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">DNS 2</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="dns2" value={dns2} onChange={(e) => setDns2(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">BandWidth</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="bandwidth" value={bandwidth} onChange={(e) => setBandwidth(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Connection Type</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="connectionType" value={connectionType} onChange={(e) => setConnectionType(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">RDS Port No.</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="rdsPort" value={rdsPort} onChange={(e) => setRdsPort(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Router URL</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="routerUrl" value={routerUrl} onChange={(e) => setRouterUrl(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Router Username</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="routerUsername" value={routerUsername} onChange={(e) => setRouterUsername(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Router Password</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="routerPassword" value={routerPassword} onChange={(e) => setRouterPassword(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">DMZ Host</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="dmzHost" value={dmzHost} onChange={(e) => setDmzHost(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">SSID Name</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="ssidName" value={ssidName} onChange={(e) => setSsidName(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">SSID Password</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="ssidPassword" value={ssidPassword} onChange={(e) => setSsidPassword(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">AP URL</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="apUrl" value={apUrl} onChange={(e) => setApUrl(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">AP Username</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="apUsername" value={apUsername} onChange={(e) => setApUsername(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">AP Password</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable ? false : true}
                    name="apPassword" value={apPassword} onChange={(e) => setApPassword(e.target.value)} />
                </div>
              </div>
            </div>
            {isEditable && (
              <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                  type="Submit"
                  value="Submit"
                />                            
              </div>
            )}
          </div>
        </form>
      )}
    </div>
  );
}
