import React, { useState }  from "react";
// components
//import HomeNavbar from "../../components/Navbars/HomeNavbar";
import UserCompanyAttendance from "../../../components/Cards/UserCompanyAttendance.js";

export default function CompanyAttendance() {
  
  return (
    <>
      
      <main>
        <div className="relative block py-24 lg:pt-0 ">
          <UserCompanyAttendance></UserCompanyAttendance>
        </div>
      </main>
      
    </>
  );
}
