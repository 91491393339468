import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../../../components/Loadder"; 
import { getData, postData } from "../../../../serverRequest";

export default function ServiceReportData(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const productserviceId = searchParams.get('productservice_id');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [serviceName, setServiceName] = useState("");  
  const [userCount, setUserCount] = useState("");  
  const [startDate, setStartDate] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [licenseEdition, setLicenseEdition] = useState("");
  const [computerId, setComputerId] = useState("");
  const [vendor, setVendor] = useState("");
  const [invoiceDetails, setInvoiceDetails]  = useState("");
  const [amount, setAmount] = useState("");
  const [entityName, setEntityName] = useState("");
  const [subscriptionPeriod, setSubscriptionPeriod] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  
  const handleSubmit = async (event) => {  
    event.preventDefault();
    setLoading(true);
    
    const formData = {
      'id': productserviceId ? productserviceId : '',
      'service_name': serviceName ? serviceName : '',
      'user_count': userCount ? userCount : '',
      'start_date': startDate ? startDate : '',
      'expire_date': expireDate ? expireDate :'',
      'license_edition': licenseEdition ? licenseEdition : '',
      'computer_id': computerId ? computerId :'',
      'vendor': vendor ? vendor : '',
      'invoice_details': invoiceDetails ? invoiceDetails : '',
      'amount': amount ? amount : '',
      'entity_name': entityName ? entityName : '',
      'subscription_period': subscriptionPeriod ? subscriptionPeriod : '',
    };
  
    try {
      console.log(formData);
      const res = await postData('/api/product/productservice/', formData); 
      console.log(res);
      if (res['data']['code'] === 200) {
        setLoading(false);
        setShowModal(true);
        alert('Service report submitted successfully');
        window.location.href = '/admin/reports/serviceReport'; 
      } else {
        alert('Service report submission failed');
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }  
  };
  
  useEffect(() => {
    async function fetchData() {
      let res = await getData('/api/product/productservice?productservice_id=' + productserviceId)
      let service_data = res['data']['productservice_data']
      console.log(service_data);
      if(service_data){
        setServiceName(service_data['service_name']);
        setUserCount(service_data['user_count']);
        setStartDate(service_data['start_date']);
        setExpireDate(service_data['expire_date']);
        setLicenseEdition(service_data['license_edition']);
        setComputerId(service_data['computer_id']);
        setVendor(service_data['vendor']);
        setInvoiceDetails(service_data['invoice_details']);
        setAmount(service_data['amount']);
        setEntityName(service_data['entity_name']);
        setSubscriptionPeriod(service_data['subscription_period']);
      }
       
      setLoading(false);
    }
    fetchData();  
  
  }, [])
  
  return (
    <div className="service-report-data-container p-4 pt-0 min-h-screen w-full">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <form onSubmit={handleSubmit}> 
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-6 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Service Report ID : {productserviceId}</h6>
                <button 
                  className={`cursor-pointer px-0 py-0 text-xl mr-2 leading-none bg-transparent hover:outline-none focus:outline-none rounded ${ isEditable ? "opacity-100 text-blue-600" : "opacity-30 text-blueGray-500" }`}
                  type="button" onClick={() => setIsEditable(isEditable ? false : true)}
                >
                  <i className={`fas fa-edit text-xl hover:outline-none focus:outline-none ${ isEditable ? 'text-lightBlue-500' : 'text-blueGray-400' }`}></i>
                </button>
              </div>
            </div>            
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">  
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Service Name</label>
                  <input type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name= "serviceName" value={serviceName} onChange={(e) => setServiceName(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">User Count</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="userCount" value={userCount} onChange={(e) => setUserCount(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Start Date</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Expire Date</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="expireDate" value={expireDate} onChange={(e) => setExpireDate(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">License Edition</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="licenseEdition" value={licenseEdition} onChange={(e) => setLicenseEdition(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Computer ID</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="computerId" value={computerId} onChange={(e) => setComputerId(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Vendor</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="vendor" value={vendor} onChange={(e) => setVendor(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Invoice Details</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="invoiceDetails" value={invoiceDetails} onChange={(e) => setInvoiceDetails(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Amount</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Entity Name</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled
                    name="entityName" value={entityName} onChange={(e) => setEntityName(e.target.value)} />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Subscription Period</label>
                  <input type="text" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    disabled={isEditable?false:true}
                    name="subscriptionPeriod" value={subscriptionPeriod} onChange={(e) => setSubscriptionPeriod(e.target.value)} />
                </div>
              </div>
            </div>
            {isEditable && (
              <div className="text-center mt-6 lg:w-3/12 px-4">
                <input
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                  type="Submit"
                  value="Submit"
                />                            
              </div>
            )}  
          </div>
        </form>
      )}
    </div>
  );
}
