import React, { useState } from "react";
import PropTypes from "prop-types";
import { getData } from "../../serverRequest";
import LoadingSpinner from "../../components/Loadder";
import moment from 'moment';
import AdminTicketListTable from "../Tables/AdminTicketListTable";
const XLSX = require('xlsx');
// components



export default function AdminTicketList(props) {
  const [open, setOpen] = React.useState(true);
  const companyID = props.companyId;
  const [openTickets, setOpenTickets] = React.useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = React.useState(companyID);
  const [totalTickets, setTotalTickets] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const searchParams = new URLSearchParams(window.location.search);

  const ticketFilter = searchParams.get('filter');
  console.log(props);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
  };

  const filterTicketData = (tickets_data) => {
    //console.log(tickets_data);
    let openTicket = [];
    let closeTicket = [];
    let totalTicket = [];
    if (tickets_data) {
      for (let i = 0; i < tickets_data.length; i++) {
        if (tickets_data[i]['status'] !== 'Resolved') {
          openTicket.push(tickets_data[i]);
          totalTicket.push(tickets_data[i]);
        }
        else if (tickets_data[i]['status'] === 'Resolved') {
          closeTicket.push(tickets_data[i]);
          totalTicket.push(tickets_data[i]);
        }
        else {
          totalTicket.push(tickets_data[i]);
        }
      }
    }

    if (ticketFilter == 'open') {
      totalTicket = openTicket;
    }
    else if (ticketFilter == 'closed') {
      totalTicket = closeTicket
    }

    //console.log(totalTicket);
    setTotalTickets(totalTicket);
    return ({ "open": openTicket, "close": closeTicket, "total": totalTicket })
  }
  React.useEffect(() => {

    async function fetchData() {
      await setSelectedCompanyId(selectedCompanyId);
      if (selectedCompanyId) {
        let resData = await getData('/api/tickets/?admin=1&company_id=' + selectedCompanyId)
        let tickets_data = resData['data']['tickets_data']
        //console.log(tickets_data);
        let ticketData = await filterTicketData(tickets_data)
      }
      else if (ticketFilter){
        let resData = await getData(`/api/tickets/?admin=1&?page=${page}&page_size=${pageSize}&ticket_filter=${ticketFilter}`)
        let tickets_data = resData['data']['tickets_data']
        
        //console.log(tickets_data);
        let ticketData = await filterTicketData(tickets_data)        
      }
      else {
        let resData = await getData('/api/tickets/?admin=1')
        let tickets_data = resData['data']['tickets_data']
        //console.log(tickets_data);
        let ticketData = await filterTicketData(tickets_data)
      }



      setLoading(false);
    }
    fetchData();


  }, [])

  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (<>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
            ("light" === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
          }
        >

          <div className="block w-full overflow-x-auto py-24 lg:pt-0 px-4" key={selectedCompanyId?selectedCompanyId:0}>
            {/* Projects table */}
            <button
              onClick={() => {
                const ws = XLSX.utils.json_to_sheet(totalTickets);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Tickets");
                XLSX.writeFile(wb, "tickets.xlsx");
              }}
              className="text-blueGray-700 text-xl font-bold"
            >
              Download Tickets
            </button>
            <AdminTicketListTable totalTickets={totalTickets}></AdminTicketListTable>

          </div>
        </div>
      </>)}
    </>
  );
}

AdminTicketList.defaultProps = {
  color: "light",
};

AdminTicketList.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
