import React, { useState } from "react";
import PropTypes from "prop-types";
import { getData } from "../../serverRequest";
import LoadingSpinner from "../../components/Loadder";
// import moment from 'moment';
// components
import UserSolutionsTable from "../Tables/UserSolutionsTable";



export default function CompanyTickets({ color }) {
  // const [open, setOpen] = React.useState(true);
  // const [openTickets, setOpenTickets] = React.useState([]);
  // const [closedTicket, setclosedTicket] = React.useState();
  const [totalTickets, setTotalTickets] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const searchParams = new URLSearchParams(window.location.search);
  
  const ticketFilter = searchParams.get('filter');
  //console.log(ticketFilter);
  // const toggleDrawer = () => {
  //   setOpen(!open);
  // };

  // const formatDate = (dateString) => {
  //   return moment(dateString).format('DD/MM/YYYY');
  // };

  const filterTicketData = (tickets_data) => {
    //console.log(tickets_data);
    let openTicket = [];
    let closeTicket = [];
    let totalTicket = [];
    if(tickets_data){
      for (let i = 0; i < tickets_data.length; i++) {
        if (tickets_data[i]['status'] !== 'Resolved') {
          openTicket.push(tickets_data[i]);
          totalTicket.push(tickets_data[i]);
        }
        else if (tickets_data[i]['status'] === 'Resolved') {
          closeTicket.push(tickets_data[i]);
          totalTicket.push(tickets_data[i]);
        }
        else{
          totalTicket.push(tickets_data[i]);
        }
      }
    }
    
    if(ticketFilter === 'open'){
      totalTicket = openTicket;
    }
    else if(ticketFilter === 'closed'){
      totalTicket = closeTicket
    }
    
    //console.log(totalTicket);
    setTotalTickets(totalTicket);
    return ({ "open": openTicket, "close": closeTicket, "total": totalTicket })
  }
  React.useEffect(() => {
    async function fetchData() {
      let resData = await getData('/api/tickets/')
      let tickets_data = resData['data']['company_tickets_data']
      //console.log(tickets_data);
      let ticketData = await filterTicketData(tickets_data)

     
      setLoading(false);
    }
    fetchData();


  }, [])

  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (<>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
            "bg-white"
          }
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1 ">
                <h3
                  className={
                    "font-semibold text-lg " +
                    "text-blueGray-700"
                  }
                >
                  Company Tickets
                </h3>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto mb-0 px-4 py-3">
            <UserSolutionsTable totalTickets={totalTickets}></UserSolutionsTable>
            {/* Projects table */}
            
          </div>
        </div>
      </>)}
    </>
  );
}

CompanyTickets.defaultProps = {
  color: "light",
};

CompanyTickets.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
